@font-face {
  font-family: '넥슨Lv1고딕 Low OTF';
  src: local('넥슨Lv1고딕 Low OTF'), local('넥슨Lv1고딕 Low OTF '), local('NEXON Lv1 Gothic Low OTF'), local('NEXON Lv1 Gothic Low OTF '), url(./NEXON_Lv1_Gothic_Low_OTF_Light.woff2) format('woff2'), url(./NEXON_Lv1_Gothic_Low_OTF_Light.woff) format('woff');
  font-display: swap;
  font-weight: 300;
}
@font-face {
  font-family: '넥슨Lv1고딕 Low OTF';
  src: local('넥슨Lv1고딕 Low OTF'), local('넥슨Lv1고딕 Low OTF '), local('NEXON Lv1 Gothic Low OTF'), local('NEXON Lv1 Gothic Low OTF '), url(./NEXON_Lv1_Gothic_Low_OTF.woff2) format('woff2'), url(./NEXON_Lv1_Gothic_Low_OTF.woff) format('woff');
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: '넥슨Lv1고딕 Low OTF';
  src: local('넥슨Lv1고딕 Low OTF'), local('넥슨Lv1고딕 Low OTF '), local('NEXON Lv1 Gothic Low OTF'), local('NEXON Lv1 Gothic Low OTF '), url(./NEXON_Lv1_Gothic_Low_OTF_Bold.woff2) format('woff2'), url(./NEXON_Lv1_Gothic_Low_OTF_Bold.woff) format('woff');
  font-display: swap;
  font-weight: 700;
}
